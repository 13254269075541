<template>
    <div class="body fixed mobile campaign-page">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <Spinner :spin="!isCampaignsLoaded"/>
        <!-- Header -->
        <section class="header activity">
            <div class="flexH width">
                <a
                    class="icon iconButton third"
                    @click.prevent="$router.push('/home')"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">縣民任務</span>
            <div class="flexH width right">
                <!-- <a class="textButton fM third" @click.prevent="$router.push('/campaign-records')">獲獎紀錄</a> -->
            </div>
        </section>
        <!-- Main -->
        <section class="main activity">
            <div v-if="campaigns.length > 0" class="cards">
                <!-- 最新任務 -->
                <a
                    class="news flexV news-card"
                    v-for="(campaign, index) in campaigns"
                    :key="'campaign' + index"
                    @click.prevent="toCampaignDetail(campaign)"
                >
                    <div class="uide-mission-card">
                        <div class="uide-mission-card-top">
                            <div :class="campaign.departmentName && 'uide-mission-card-top-chip'">{{ campaign.departmentName}}</div>
                            <div>{{ tsToDateDay(campaign.campaignStartTime) }}</div>
                        </div>
                        <div class="uide-mission-card-title">
                            {{ campaign.campaignName}}
                        </div>
                        <div class="ellipsis-two-line">
                            {{ parseContent(campaign.campaignDesc) }}
                        </div>
                        <div class="flex gap-1 items-center">
                            <img  width="20" height="20" src="@/assets/icon/uide/coin.svg" />
                            <span v-if="campaign.reward_point > 0">雲林幣{{ campaign.reward_point }}枚</span>
                            <span v-else>小遊戲抽獎機會 1 次</span>
                        </div>
                        <div class="flex gap-1 items-center justify-between ">
                            <ProgressBar  style="width:228px;" :progress="((campaign.reward_total - campaign.reward_remain) / campaign.reward_total) * 100"/>
                            <div :class="progressBtnClass(campaign)">{{ getStatusName(campaign) }}</div>
                        </div>
                        <div class="flex gap-1 items-center ">
                            <img  width="16" height="16" src="@/assets/icon/uide/people.svg" />
                            <span class="uide-mission-card-user">獎勵剩餘人數 {{ campaign.reward_remain }} 名 / 總人數 {{ campaign.reward_total }} 名</span>
                        </div>
                        <img src="@/assets/icon/bell-v2.svg"
                            :class="['uide-mission-card-bell', { hide: campaign.unread === false }]"
                        />
                    </div>
                </a>
            </div>
            <div v-else>
                <!-- 無內容 -->
                <div class="listEmpty flexV width center middle">
                    <div class="icon secondary"><font-awesome-icon icon="fa-solid fa-dice-d6" size="10x"></font-awesome-icon></div>
                    <span class="fXXL">沒有縣民任務</span>
                    <span class="fL gray">明天再來看看吧</span>
                </div>
            </div>
        </section>
        <AlertDialog
            v-if="showAlert"
            :title="alert.title"
            :content="alert.message"
            @close="onCloseAlertDialog"
        />
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';
import ProgressBar from '@/components/ProgressBar.vue';
import AlertDialog from '@/components/Dialog/AlertDialog.vue'

export default {
    name: "",
    components: {
        Spinner,
        ProgressBar,
        AlertDialog
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaigns: [],
            isCampaignsLoaded: false,
            showAlert: false,
            alert: {
                title: '',
                message: ''
            },
            statusNames: {
                0: "快來搶獎勵",
                1: "已領取獎勵",
                2: "獎勵已搶完",
                3: "您已參加"
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    computed: {
        ...mapState(["user", "userInfo"]),
    },
    mounted() {
        this.getCampaigns();
    },
    methods: {
        isOverToday(campaign) {
            const today = moment();
            const endTime = moment(campaign.campaignEndTime * 1000);
            return endTime.isBefore(today);
        },
        progressBtnClass(campaign) {
            const status = this.getStatusId(campaign);

            return {
                "uide-mission-card-progress-btn": true,
                [`status${status}`]: true
            };
        },
        parseContent(content) {
            let result = content.replace(/<\/?[^>]+(>|$)/g, "");
            result = result.replace(/&[^;\s]+;/g, "");
            result = result.trim();
            return result;
        },
        getStatusId(campaign) {
            if (campaign.campaignPrototype === 'qna' && !!campaign.hasCompleted && !campaign.reward_status) {
                return 3
            }
            return (campaign.hasCompleted && campaign.campaignPrototype !== "referral-referrer") ? 1 : (campaign.reward_remain > 0 ? 0 : 2)
        },
        getStatusName(campaign) {
            const idx = this.getStatusId(campaign);
            return this.statusNames[idx] || "Unknown";
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        campaignDummy() {
            let result = {
                uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
                memberId: 208,
                campaigns: [
                    {
                        campaignId: "M_15KpZMpZb6",
                        campaignName: "消費集章任務 New",
                        campaignDesc:
                            "多間店家，消費一間集章一枚。滿足集章數目條件可獲獎！",
                        campaignStatus: "approved",
                        campaignPrototype: "stamp-collection",
                        campaignStartTime: 1652803200,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652878774,
                        completeness: "completed",
                        missions: [
                            {
                                missionId: 78,
                                missionName: "消費集章",
                                missionDesc: "滿足集章數量條件可以獲獎。",
                                settings: {
                                    milestones: [
                                        {
                                            rewardId: 52,
                                            completionCount: 2
                                        },
                                        {
                                            rewardId: 53,
                                            completionCount: 5
                                        }
                                    ]
                                },
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652879229
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652879273
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652879336
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652879338
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652879359
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652888318
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_PkJBaqrykM",
                        campaignName: "活動報到賺點數",
                        campaignDesc:
                            "至指定活動地點出示個人條碼給工作人員掃描，即可獲得20點",
                        campaignStatus: "approved",
                        campaignPrototype: "check-in",
                        campaignStartTime: 1652803200,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652866222,
                        completeness: "completed",
                        missions: [
                            {
                                missionId: 77,
                                missionName: "活動報到賺點數",
                                missionDesc:
                                    "至指定活動地點出示個人條碼給工作人員掃描，即可獲得20點",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652867075
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652867151
                                    },
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652867242
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_Rdcampaign7eY5B",
                        campaignName: "小遊戲：幸運轉盤",
                        campaignDesc:
                            "參加幸運轉盤即有機會獲得點數，每日限參加一次。",
                        campaignStatus: "approved",
                        campaignPrototype: "minigame",
                        campaignStartTime: 1652284800,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652322816,
                        completeness: "not-participating",
                        missions: [
                            {
                                missionId: 58,
                                missionName: "小遊戲：幸運轉盤",
                                missionDesc:
                                    "參加幸運轉盤即有機會獲得點數，每日限參加一次。",
                                settings: null
                            }
                        ]
                    },
                    {
                        campaignId: "M_zkeWEKBM5y",
                        campaignName: "VIP限定",
                        campaignDesc: "VIP會員掃描點數卡條碼即可獲得20點",
                        campaignStatus: "approved",
                        campaignPrototype: "check-in",
                        campaignStartTime: 1652198400,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652260188,
                        completeness: "not-participating",
                        missions: [
                            {
                                missionId: 42,
                                missionName: "VIP限定",
                                missionDesc:
                                    "VIP會員掃描點數卡條碼即可獲得20點",
                                settings: null
                            },
                            {
                                missionId: 64,
                                missionName: "VIP給點（點數店家指定）",
                                missionDesc: "完成此任務者會給點，點數待定",
                                settings: null
                            }
                        ]
                    },
                    {
                        campaignId: "M_xd48VG9Vdj",
                        campaignName: "觀看影片賺點數",
                        campaignDesc:
                            "看宣導影片，完整觀看即獲得20點，每日限看一次",
                        campaignStatus: "approved",
                        campaignPrototype: "video",
                        campaignStartTime: 1652198400,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652260163,
                        completeness: "completed",
                        missions: [
                            {
                                missionId: 43,
                                missionName: "影片關卡",
                                missionDesc: "觀看影片完成者有獎",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652765809
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_xd4wXAwvkj",
                        campaignName: "推薦好友任務",
                        campaignDesc:
                            "成功推薦好友加入，自己與好友皆可獲得100點\r\n\r\n",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652025600,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652070487,
                        completeness: "incomplete",
                        missions: [
                            {
                                missionId: 31,
                                missionName: "測試推薦任務 之 推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項",
                                settings: null,
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1652092171
                                    },
                                    {
                                        status: "completed",
                                        completedAt: 1652092477
                                    }
                                ]
                            },
                            {
                                missionId: 32,
                                missionName: "推薦任務-推薦人獎勵",
                                missionDesc:
                                    "成功推薦好友即可獲得，每推薦一人可領一次",
                                settings: null
                            },
                            {
                                missionId: 34,
                                missionName: "推薦任務-被推薦人獎勵",
                                missionDesc:
                                    "被推薦人成功註冊即可獲得，只能領一次",
                                settings: null
                            }
                        ]
                    },
                    {
                        campaignId: "M_p5O76qR854",
                        campaignName: "註冊任務",
                        campaignDesc: "成功註冊即可獲得100點",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1651132800,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1651129632,
                        completeness: "completed",
                        missions: [
                            {
                                missionId: 22,
                                missionName: "會員註冊任務",
                                missionDesc:
                                    "只要成功註冊會員即可獲得，每人限領一次",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652253394
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_1dXv60L0bQ",
                        campaignName: "點數消費與集章任務",
                        campaignDesc:
                            "至指定特店用點集章(每店限1章)。滿2章贈100點, 滿5章即完成集章再贈200點",
                        campaignStatus: "approved",
                        campaignPrototype: "stamp-collection",
                        campaignStartTime: 1651132800,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1651129549,
                        completeness: "incomplete",
                        missions: [
                            {
                                missionId: 23,
                                missionName: "點數消費與集章任務",
                                missionDesc:
                                    "於光咖啡Guang Café店家用點數消費一次",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652668418
                                    }
                                ]
                            },
                            {
                                missionId: 24,
                                missionName: "點數消費與集章任務",
                                missionDesc: "於寶廚刀削麵店家用點數消費一次",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652668428
                                    }
                                ]
                            },
                            {
                                missionId: 25,
                                missionName: "點數消費與集章任務",
                                missionDesc:
                                    "於職人雙饗丼市府店店家用點數消費一次",
                                settings: null
                            },
                            {
                                missionId: 26,
                                missionName: "點數消費與集章任務",
                                missionDesc:
                                    "於GP健康定食創意料理店家用點數消費一次",
                                settings: null
                            },
                            {
                                missionId: 27,
                                missionName: "點數消費與集章任務",
                                missionDesc:
                                    "於Miss Energy能量小姐店家用點數消費一次",
                                settings: null
                            }
                        ]
                    },
                    {
                        campaignId: "M_qkwgmRp7kL",
                        campaignName: "問答任務Q2",
                        campaignDesc: "答對即送100點",
                        campaignStatus: "approved",
                        campaignPrototype: "qna",
                        campaignStartTime: 1652922000,
                        campaignEndTime: 1653321599,
                        campaignCreatedAt: 1651129009,
                        completeness: "completed",
                        missions: [
                            {
                                missionId: 18,
                                missionName: "問答任務Q2",
                                missionDesc: "答對即送100點",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652856802
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_15KpL839b6",
                        campaignName: "問答任務Q1",
                        campaignDesc: "答對即送100點",
                        campaignStatus: "approved",
                        campaignPrototype: "qna",
                        campaignStartTime: 1652922000,
                        campaignEndTime: 1653321599,
                        campaignCreatedAt: 1651128584,
                        completeness: "completed",
                        missions: [
                            {
                                missionId: 17,
                                missionName: "問答任務Q1",
                                missionDesc: "答對即送100點",
                                settings: null,
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652856784
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                resolve({ data: result });
            });
            return promise;
        },
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal&return=list`,
                methods: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            const now = moment();
            this.getCampaignAPI()
            // this.campaignDummy()
                .then((res) => {
                    this.campaigns = res.data.campaigns;
                    this.campaigns = this.campaigns.filter((c) => {
                        const campaignEndTime = moment(c.campaignEndTime * 1000);

                        if (campaignEndTime.isBefore(now)) {
                            return false;
                        }

                        if (c.campaignStatus !== 'approved') {
                            return false
                        }

                        // const res = ![ 'registration', 'complete-profile', 'referral-recipient'].includes(c.campaignPrototype)
                        const res = ['referral-referrer', 'check-in', 'daily-sign'].includes(c.campaignPrototype)
                        return res
                    });
                    //NOTE: 只顯示visibility = 'public'的任務
                    this.sortCampaigns();
                })
                .finally(()=>{
                    this.isCampaignsLoaded = true;
                });
        },
        sortCampaigns() {
            let incompleteCampaigns = this.campaigns.filter(c => c.completeness == 'incomplete');
            let notParticipatingCampaigns = this.campaigns.filter(c => c.completeness == 'not-participating');
            let completedCampaigns = this.campaigns.filter(c => c.completeness == 'completed');
            let completedNoRewardCampaigns = this.campaigns.filter(c => c.completeness == 'completed-no-reward');

            function sortByTsDesc(b, a) {
                return a.campaignStartTime - b.campaignStartTime;
            }
            incompleteCampaigns.sort(sortByTsDesc);
            notParticipatingCampaigns.sort(sortByTsDesc);
            completedCampaigns.sort(sortByTsDesc);
            completedNoRewardCampaigns.sort(sortByTsDesc);
            this.campaigns = incompleteCampaigns.concat(notParticipatingCampaigns).concat(completedCampaigns).concat(completedNoRewardCampaigns);
        },
        calMissions(campaign) {
            if(campaign && campaign.campaignPrototype == "stamp-collection" && campaign.missions && campaign.missions.length == 1) {
                let settings = campaign.missions[0].settings;
                if(settings && settings.milestones && settings.milestones.length > 0) {
                    let max = Math.max(...settings.milestones.map(milestone => milestone.completionCount));
                    return max;
                } else {
                    return 0;
                }
            } else {
                if (
                    campaign &&
                    campaign.missions &&
                    campaign.missions.length >= 0
                ) {
                    return campaign.missions.length;
                }
            }
            return 0;
        },
        calCompletedMissions(campaign) {
            if(campaign && campaign.campaignPrototype == "stamp-collection" && campaign.missions && campaign.missions.length == 1) {
                let participation = campaign.missions[0].participation;
                if(participation && participation.length > 0) {
                    return participation.filter(p => p.status == "completed").length;
                } else {
                    return 0;
                }
            } else {
                if (
                    campaign &&
                    campaign.missions &&
                    campaign.missions.length > 0 &&
                    campaign.missions.some((m) => m.participation)
                ) {
                    return campaign.missions.filter((m) => {
                        if (
                            m.participation &&
                            m.participation.length > 0 &&
                            m.participation[0].status == "completed"
                        ) {
                            return true;
                        }
                        return false;
                    }).length;
                }
            }
            return 0;
        },
        calNumerator(campaign) {
            let numerator = this.calCompletedMissions(campaign);
            let denominator = this.calMissions(campaign);
            return numerator > denominator ? denominator : numerator;
        },
        calProgress(campaign) {
            let numerator = this.calNumerator(campaign);
            let denominator = this.calMissions(campaign);
            return (numerator / denominator) * 100;
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        checkCampaignCompleted(campaign) {
            return campaign.completeness == 'completed';
        },
        processBarDisplay(campaign) {
            return campaign.campaignPrototype == "stamp-collection";
        },
        onCloseAlertDialog() {
            this.showAlert = false;
            this.alert = {
                title: '',
                message: ''
            }
            this.getCampaigns();
        },
        toCampaignDetail(campaign) {
            if (this.isOverToday(campaign)) {
                this.alert = {
                    title: '提醒',
                    message: '任務已下架'
                }
                this.showAlert = true
                return;
            }

            this.$store.commit("updateCampaign", campaign);
            this.$router.push(`/campaign/${campaign.campaignId}/detail`);
        },
        checkRewardCompleted(campaign) {
            return (campaign.reward_remain === 0);
        },
        showRewordInsufficient(campaign) {
            const {
                reward_remain,
                reward_total,
                hasCompleted,
                campaignPrototype,
            } = campaign;
            
            const isInsufficient =
                reward_remain > 0 &&
                reward_remain <= reward_total * 0.05;

            if (
                campaignPrototype === 'referral' ||
                campaignPrototype === 'stamp-collection'
            ) {
                return isInsufficient;
            } else {
                return !hasCompleted && isInsufficient;
            }
        },
        showCampaignCompleted(campaign) {
            return (
                this.checkCampaignCompleted(campaign) &&
                campaign.campaignPrototype !== 'referral' &&
                campaign.campaignPrototype !== 'stamp-collection'
            );
        },
    }
};
</script>
<style scoped lang="scss">
.flexV.width.margin.mt-2.news {
    margin-top: 20px!important;
}
.campaign-page {
    .cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }
    .news-card {
        width: 100% !important;
        margin: 0 !important;

        .uide-mission-card {
            margin: 0 !important;
        }
    }
}
</style>